<template>
  <div>
    <MyDashboard></MyDashboard>
    <!-- {{me}} -->
  </div>
</template>
<script>
import MyDashboard from "@/components/frontend/MyDashboard/MyDashboard.vue";
export default {
  name: "Dashboard",
  components: {
    MyDashboard,
  },
  mounted() {
    this.$store.dispatch("getMe");
    this.$store.dispatch("getcustomerSummery");
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
//@import "@/assets/frontend/style/checkout.scss";
</style>
