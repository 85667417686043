<template>
  <section class="product_details">
    <div class="container">
      <div class="dashboard">
        <div class="container-fluid">
          <div class="row">
            <div class="profile-top-bar">
              <div class="dashboard-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a></li>

                  </ol>
                </nav>
                <div class="text-right" style="color: white">
                  {{ me.customer_name }}
                </div>
                <!--<div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ me.customer_name }}
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#"> Profile</a>
                    <a class="dropdown-item" href="#"
                      ><i class="fa fa-sign-out pull-right"></i> Log Out</a
                    >
                  </div>
                </div>-->
              </div>
            </div>
          </div>

          <div class="row row-flex">
            <Navbar></Navbar>

            <div class="col-md-9 eqal-col" style="padding-left: 5px">
              <div class="dashboard-right">
                <div class="dashboard-quotation">
                  <div class="container-fluid dashboard-counter" id="dashboard_right">
                    <div class="row">
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Life Time Order(s)</h6>
                          <div class="statistic-left">
                            <i class="icon ion-md-list-box"></i>
                          </div>
                          <div class="statistic-right">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.total_orders"
                            >
                              {{ customerSummery.total_orders }}
                            </h4>
                            <h4 class="counter-count" v-else>0 TK</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Complete Order(s)</h6>
                          <div class="statistic-left">
                            <i class="icon ion-md-heart"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.total_completed_order"
                            >
                              {{ customerSummery.total_completed_order }}
                            </h4>
                            <h4 class="counter-count" v-else>0 TK</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Wishlist(s)</h6>
                          <div class="statistic-left">
                            <i class="icon ion-md-heart"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right">
                            <h4 class="counter-count" v-if="customerSummery.product_wishlist_count">
                              {{ customerSummery.product_wishlist_count }}
                            </h4>
                            <h4 class="counter-count" v-else>0</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Pending Order(s)</h6>
                          <div class="statistic-left">
                            <i class="icon ion-md-code-working"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.total_pending_order"
                            >
                              {{ customerSummery.total_pending_order }}
                            </h4>
                            <h4 class="counter-count" v-else>0 TK</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Cancel Order(s)</h6>
                          <div class="statistic-left">
                            <i class="icon ion-md-sad"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.total_cancelled_order"
                            >
                              {{ customerSummery.total_cancelled_order }}
                            </h4>
                            <h4 class="counter-count" v-else>0 TK</h4>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Order Amount</h6>
                          <div class="statistic-left-amount">
                            <i class="icon ion-logo-usd"></i>
                          </div>
                          <div class="statistic-right-amount">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.total_order_price"
                            >
                              {{ customerSummery.total_order_price }}Tk
                            </h4>
                            <h4 class="counter-count" v-else>0 Tk</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Completed Amount</h6>
                          <div class="statistic-left-amount">
                            <i class="icon ion-logo-usd"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right-amount">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.completed_order_total_price"
                            >
                              {{
                                customerSummery.completed_order_total_price
                              }}Tk
                            </h4>
                            <h4 class="counter-count" v-else>0 Tk</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Unshipped Amount</h6>
                          <div class="statistic-left-amount">
                            <i class="icon ion-logo-usd"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right-amount">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.pending_order_total_price"
                            >
                              {{ customerSummery.pending_order_total_price }}Tk
                            </h4>
                            <h4 class="counter-count" v-else>0 Tk</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Cancel Amount</h6>
                          <div class="statistic-left-amount">
                            <i class="icon ion-logo-usd"></i>
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right-amount">
                            <h4
                              class="counter-count"
                              v-if="customerSummery.cancelled_order_total_price"
                            >
                              {{
                                customerSummery.cancelled_order_total_price
                              }}Tk
                            </h4>
                            <h4 class="counter-count" v-else>0 Tk</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Earned Pont </h6>
                          <div class="statistic-left-amount">
  <!--                            <i class="icon ion-logo-usd"></i>-->
                            <!--  <p><a href="#"><img src="images/icon-start.png" alt="From the start">From the start</a></p> -->
                          </div>
                          <div class="statistic-right-amount">
                            <h4 class="counter-count" >
                              {{customerSummery.customer_point_earn}}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Total Used Pont</h6>
                          <div class="statistic-left-amount">
                          </div>
                          <div class="statistic-right-amount">
                            <h4 class="counter-count">
                              {{customerSummery.customer_point_use}}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="statistic">
                          <h6>Remaining Point</h6>
                          <div class="statistic-left-amount">
                          </div>
                          <div class="statistic-right-amount">
                            <h4 class="counter-count">
                              {{customerSummery.customer_point_earn - customerSummery.customer_point_use}}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="premium_plan">
                          <div class="plan_box_content">
                            <div class="regular_member items">
                              <div class="content">
                                <!-- {{ me }} -->
                                <h3>Account Information</h3>
                                <ul class="list">
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; Name: {{ me.customer_name }}</span
                                    >
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; Address:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.customer_address
                                      }}
                                    </span>
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; Area Name:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.area && me.customer_address.area.name
                                      }}
                                    </span>
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span>&nbsp; Division:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.division && me.customer_address.division.name
                                      }}
                                  </span
                                    >
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; District:
                                      {{
                                        me.customer_address &&
                                        me.customer_address.district && me.customer_address.district.name
                                      }}</span
                                    >
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; Zip:
                                      <!--{{
                                        me.customer_address &&
                                        me.customer_address.zip
                                      }}--></span
                                    >
                                  </li>
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; PHone:
                                      {{ me.customer_phone }}</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="premium_member items">
                              <div class="content">
                                <h3>Premium Customer</h3>
                                <ul class="list">
                                  <li>
                                    <i class="icon ion-ios-arrow-dropright"></i>
                                    &nbsp;<span
                                      >&nbsp; No watermark on the banner
                                      slider</span
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/frontend/MyDashboard/Navbar.vue";
export default {
  computed: {
    ...mapGetters(["me", "customerSummery","customerPoints"]),
  },
  mounted() {
    this.$store.dispatch("getCustomerPoints");
  },
  components: {
    Navbar,
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/dashboard.scss";
@import "@/assets/frontend/style/details.scss";
@import "@/assets/frontend/style/bs_leftnavi.scss";
@import "@/assets/frontend/style/customize.scss";

h4.counter-count {
  font-size: 17px !important;
  margin-top: 9px;
}
</style>
